import React, { Suspense, lazy } from "react";
import styles from "./NewLandingPage.module.scss";
import nxt_trade from "../../assets/landingPage/nxt-trade.png";
import nxt_tech from "../../assets/landingPage/nxt-tech.png";
import chevron from "../../assets/landingPage/chervon.svg";
import splash from "../../assets/Animations/splash.gif";
import { ScrollAnimation } from "../../components/scrollAnimation/ScrollAnimation";
const Banner = lazy(() => import("../../components/Banner/Banner"));
const About = lazy(() => import("../../components/about/About"));
const Fraud = lazy(() => import("../../components/fraud/Fraud"));
const Csr = lazy(() => import("../../components/csr/Csr"));
const News = lazy(() => import("../../components/news/News"));
const AutoPlay = lazy(() => import("../../components/slider/Slider"));
const Counts = lazy(() => import("../../components/counts/Counts"));
const BigPicture = lazy(() => import("../../components/bigPicture/BigPicture"));

interface NewLandingPageProps {}

export const NewLandingPage: React.FC<NewLandingPageProps> = () => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <img src={splash} alt="splash" height={150} width={150} />
        </div>
      }
    >
      <div className={styles.landingPageContainer}>
        <Suspense fallback={<></>}>
          <Banner />
        </Suspense>
        <Suspense fallback={<></>}>
          <Counts />
        </Suspense>
        <Suspense fallback={<></>}>
          <About />
        </Suspense>
        <Suspense fallback={<></>}>
          <Fraud />
        </Suspense>
        <Suspense fallback={<></>}>
          <BigPicture />
        </Suspense>
        <AutoPlay />
        <div className={styles.landingPageMiddleDetails}>
          <h1>Our sister companies</h1>
        </div>
        <div className={styles.cardContainer}>
          <ScrollAnimation animateIn="slide_in_left">
            <div className={styles.card}>
              <section>
                <img src={nxt_trade} alt="Avatar" />
              </section>
              <div className={styles.container1}>
                <section>
                  <h1 className={styles.cardTitle}>NXT Trade</h1>
                  <p className={styles.smallText}>
                    NXT Trade is a Tier 1 distributor involved in extensive
                    procurement for big corporations and government agencies. As
                    a result of NXT Trade, it is simpler for brands to get in
                    touch with the retailers they want to work with and interact
                    with their target market. They expertly deliver a product
                    from the manufacturing right up to the customer's front
                    door. It started in Lagos, Nigeria, and has since expanded
                    to several Anglophone African nations. The first branch is
                    located at Lekki Phase 1. NXT Trade collaborates with
                    Shoprite, Spar, Lafarge, Transcorp Hilton, Hartley's, and
                    numerous more brands to achieve this purpose.
                  </p>
                </section>
                <a href="/#" className={styles.button}>
                  Visit website{" "}
                  <img
                    src={chevron}
                    className={styles?.chevron}
                    alt="chevron"
                  />
                </a>
              </div>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="slide_in_right">
            <div className={styles.card}>
              <section>
                <img src={nxt_tech} alt="Avatar" />
              </section>
              <div className={styles.container2}>
                <section>
                  <h1 className={styles.cardTitle}>NXT Tech</h1>
                  <p className={styles.smallText}>
                    This was born out of a passion and need to offer
                    cutting-edge technology solutions to both businesses and
                    individuals. Since its founding in 2020, the company has
                    developed and established a reputation as an inventive,
                    forward-thinking force for social impact. Both internal and
                    external clients are served by its software solutions,
                    digital transformation and marketing which have allowed them
                    to achieve widespread recognition and have a significant,
                    lasting impact. NXT TECH is a solution incubator company
                    that provides technology solutions to assist companies with
                    every day problems that the companies may be experiencing.
                    NXT TECH enables companies to operate easier and more
                    efficiently.
                  </p>
                </section>
                <a href="/#" className={styles.button}>
                  Visit website{" "}
                  <img
                    src={chevron}
                    className={styles?.chevron}
                    alt="chevron"
                  />
                </a>
              </div>
            </div>
          </ScrollAnimation>
        </div>
        <Suspense fallback={<></>}>
          <Csr />
        </Suspense>
        <Suspense fallback={<></>}>
          {/* <News /> */}
        </Suspense>
      </div>
    </Suspense>
  );
};

export default NewLandingPage;
